import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Partners = () => {
    return (
        <section className="px-6 py-12">
            <div className="container mx-auto">
                <span className="block text-center uppercase text-lg text-gray-500 mb-12">Partner Tecnologici</span>
                <div className="flex items-center justify-between gap-24">
                    <StaticImage src="../images/yealink_logo.png" alt="Yealink" className="w-1/5" placeholder="blurred" />
                    {/* <StaticImage src="../images/voispeed_logo.png" alt="Voispeed" className="w-1/5" placeholder="blurred" /> */}
                    <StaticImage src="../images/wildix-logo.png" alt="Aruba" className="w-1/5" placeholder="blurred" />
                    <StaticImage src="../images/azure_logo.png" alt="Azure" className="w-1/5" placeholder="blurred" />
                    <StaticImage src="../images/aruba_logo.png" alt="Aruba" className="w-1/5" placeholder="blurred" />
                </div>
            </div>
        </section>
    )
}

export default Partners