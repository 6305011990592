import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
//import BackgroundImage from 'gatsby-background-image'

const HeroHome = () => {
    const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "topography-old-bat.png"}) {
        childImageSharp {
            fluid {
                src
            }
          }
      }
    }
  `)

  const imageData = data.file.childImageSharp.fluid.src
  const divStyle ={
    backgroundImage: `url(${imageData})`
  }

    return (
        <section className="px-6 py-24 lg:py-56 bg-auto bg-repeat" fluid={imageData} fadeIn={true} style={divStyle} >
            <div className="container mx-auto flex flex-col items-center justify-center space-y-3 text-center">
                <h1 className="text-5xl lg:text-6xl text-gray-800">In ogni luogo.</h1>
                <h2 className="text-5xl lg:text-6xl text-persian-green-500">Meno investimenti. Più produttività</h2>
                <span className="block pb-12">​Strutture operative avanzate per migliorare la tua produttività, anche da casa.</span>
                <div className="flex items-center space-x-4">
                    <Link to="/demo" className="button-lg bg-persian-green-500 text-white hover:bg-persian-green-700 transition-colors">Richiedi una demo</Link>
                    <Link to="/caratteristiche-per-aprire-un-call-center" alt="Scopri di più" className="button-lg bg-persian-green-500 bg-opacity-10 text-persian-green-700 hover:bg-opacity-5 transition-colors">Scopri di più</Link>
                </div>
            </div>
        </section>        
    )
}

export default HeroHome